import { FontSize, Link } from "@front-core/link";
import React, { useState } from "react";
import ArrowRightIcon from "@front-core/icon/dist/ArrowRight";
import Steps from "./Steps/Steps";
import { FaqBottomSheet } from "./FaqBottomSheet/FaqBottomSheet";

import styles from "./styles/App.module.scss";
import { useParams } from "react-router-dom";
import { FormFactor } from "types/formFactor";

export default (): JSX.Element => {
  const [open, setOpen] = useState(false);
  let { formFactor } = useParams<{formFactor: FormFactor}>();
  const isTablet = formFactor === FormFactor.TABLET;
  return (
    <>
      <div className={styles.headerBackground}>
        <img src="/header-background.svg"></img>
      </div>
      <div className={styles.divAfterHeader}></div>
      <div className={styles.content}>
        {/* ___________________________ */}
        <div className={styles.item}>
          <h2>Электронная подпись</h2>
          <p>
            Аналог обычной подписи. Позволяет онлайн подписывать документы для сделки с недвижимостью.
          </p>
        </div>
        {/* ___________________________ */}
        <div className={styles.flexWrapper}>
          <h4> Для выпуска электронной подписи нужно</h4>
          <img src="/esign.svg"></img>
        </div>
        {/* ___________________________ */}
        <div className={styles.item}>
          <Steps isTablet={isTablet}/>
        </div>
        {/* ___________________________ */}
        <div className={styles.item}>
          <h4>О безопасности</h4>
          Подпись безопасна. Её создают с помощью шифрования данных и утверждают
          в ФСБ. Подпись соответствует{" "}
          <span className={styles.fzLinkStyle}>
            63-ФЗ «Об электронной подписи»
          </span>
        </div>
        {/* ___________________________ */}
        <div className={styles.itemWithBackground}>
          <div>
            <h4>Документы, которые нужны для выпуска электронной подписи</h4>
            Оригинал паспорта, СНИЛС, ИНН
          </div>
          <div className={styles.docsWrapper}>
            <img src="/docs.svg"></img>
          </div>
        </div>
      </div>
      <FaqBottomSheet open={open} setOpen={setOpen} isTablet={isTablet}/>
    </>
  );
};
