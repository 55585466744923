import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import Support from "./Support";
import { BrowserRouter, Route, Switch } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/" children={<App />} /> 
      <Route path="/support" children={<Support />} />
      <Route path="/:formFactor" children={<App />} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
