import { BottomSheet } from "@front-core/bottom-sheet";
import { Button, Intent } from "@front-core/button";
import React from "react";

import styles from "./FaqBottomSheet.module.scss";

export const FaqBottomSheet: React.FC<{
  open: boolean;
  setOpen: (val: boolean) => void;
  isTablet: Boolean;
}> = ({ open, setOpen, isTablet }) => {
  const footerButtons = [
    <Button
      text="Понятно"
      intent={Intent.Secondary}
      onClick={() => {
        setOpen(false);
      }}
    />,
  ];
  return (
    <div>
      <BottomSheet
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        footerButtons={footerButtons}
      >
        <div className={styles.wrapper}>
          <h3>У меня уже есть электронная подпись. Её можно использовать?</h3>
          {isTablet
            ? `Для подписания документов вы можете использовать только ту подпись,
          которую выпустили в приложении Домклик Электронная подпись на
          общественном планшете`
            : `Подпись нельзя использовать, если её выпустили
          не в приложении Домклик. Чтобы использовать услугу «Электронная
          регистрация», выпустите новую подпись в приложении.`}
          <h3>Что делать с подписью, которая выпущена с помощью СМС?</h3>
          Нужно выпустить новую подпись в приложении Домклик Электронная подпись
          <h3>Почему подпись может заблокироваться?</h3>
          {isTablet
            ? `Подпись блокируется после подписания документов на общественном планшете`
            : `Подпись заблокируется, если удалить приложение или зайти с другого
            телефона в раздел «Электронная подпись».`}
        </div>
      </BottomSheet>
    </div>
  );
};
