import React from "react";
import { Stepper } from "@front-core/stepper";

export default ({isTablet}: {isTablet: boolean}) => {
  const steps = isTablet
    ? [
        { id: 1, content: "Начать сделку с недвижимостью в офисе банка" },
        { id: 2, content: "Приступить к выпуску подписи вместе с менеджером" },
        { id: 3, content: "Следовать инструкции в приложении" },
      ]
    : [
        { id: 1, content: "Начать сделку с недвижимостью" },
        { id: 2, content: "Приступить к выпуску подписи вместе с менеджером" },
        { id: 3, content: "Войти в приложение «Цифровой ключ»" },
        { id: 4, content: "Нажать «Перейти к выпуску»" },
        { id: 5, content: "Следовать инструкции в приложении" },
      ];

  return (
    <Stepper
      steps={steps}
      activeStep={6}
      completedSteps={[1, 2, 3, 4, 5]}
      vertical={true}
    />
  );
};
