import { FontSize, Link } from "@front-core/link";
import React, { useState } from "react";
import ArrowRightIcon from "@front-core/icon/dist/ArrowRight";
import { FaqBottomSheet } from "./FaqBottomSheet/FaqBottomSheet";

import styles from "./styles/App.module.scss";
import { useParams } from "react-router-dom";
import { FormFactor } from "types/formFactor";

export default (): JSX.Element => {
  return (
    <>
    <div className={styles.headerBackground}>
      <img src="/header-background.svg"></img>
    </div>
    <div className={styles.divAfterHeader}></div>
    <div className={styles.content}>
      {/* ___________________________ */}
      <div className={styles.item}>
        <h2>Вопросы и ответы</h2>
      </div>
      {/* ___________________________ */}
      <div className={styles.item}>
        <h4> У меня уже есть электронная подпись. Ее можно использовать? </h4>
        <p>Подпись нельзя использовать, если ее выпустили не в приложении «Цифровой ключ» </p>
      </div>
      <div className={styles.item}>
        <h4> Что делать с подписью, которая выпущена с помощью СМС?  </h4>
        <p>Нужно выпустить новую подпись в приложении «Цифровой ключ» </p>
      </div>
      <div className={styles.item}>
        <h4> Почему подпись может заблокироваться?  </h4>
        <p>Подпись заблокируется, если удалить приложение или зайти в него с другого смартфона </p>
      </div>
    </div>
    </>
  );
};
